import React, { Component } from "react";
import { observer } from "mobx-react";
import { Container, Icon, Step, Modal, Header, Button } from "semantic-ui-react";
import BeaconRegistrationStep from "./beacon/beaconStep";
import BeaconDetailForm from "./beacon/beaconDetailForm";
import OwnerRegistrationStep from "./beacon/ownerStep";
import VehiculeStep from "./beacon/vehiculeStep";
import OwnerEmergencyContactForm from "./beacon/ownerEmergencyContactForm";
import OwnerDetailForm from "./beacon/ownerDetailForm";
import RegistrationState from "../../state/registrationState";
import UserState from "../../state/userState";
import BeaconDetailsReview from "./beacon/beaconReviewDetails";
import RegistrationComplete from "./registrationComplete";
import RouteState from "../../state/routeState";
import i18n from "i18next";
import "./registerBeacon.css";
import { toJS } from "mobx";

@observer
export default class RegisterBeacon extends Component {
  state = {
    showModal: false,
  };

  componentDidMount() {
    RegistrationState.hasUnsavedChanges = true;
    console.log('-----> current beacon: ', toJS(this.props.beacon))
  }

  onAskToCancle = (e) => {
    let roleId = UserState.currentUserProfile.roleId;
    if (UserState.notAllowToEditByRoleId.includes(roleId)) {
      this.onModalAccept();
    } else {
      this.setState({
        showModal: true,
      });
      e.stopPropagation(); //This prevent the tab itself to register the click.
    }
  };

  onModalCancel = () => {
    this.setState({
      showModal: false,
    });
  };

  onModalAccept = () => {
    this.setState({
      showModal: false,
    });
    RegistrationState.resetSteps();
    RegistrationState.resetBeaconData();
    RouteState.setRoute("/dashboard");
  };

  onSelectStep = (event, data) => {
    /*if(!RegistrationState.isNewRegisterBeacon){
			RegistrationState.setCurrentStep(data.stepnumber)
			RegistrationState.setBeaconCurrentStep(0)
			RegistrationState.setOwnerStep(0)
			RegistrationState.setVehiculeStep(0)
		}*/
  };

  componentWillUnmount() {
    RegistrationState.setIsPrintCertificate(false);
    RegistrationState.setIsPrintSummary(false);
  }

  render() {
    let { beacon, onClose, onDashboardEdit, onSearchClick, clearFiltersForUser, onSetFilterUsername, onUpdateSearch, onAdminBeaconClick, beaconEditError } = this.props;
    let { showModal } = this.state;
    let currentStep = RegistrationState.currentStep;
    let display = RegistrationState.isNewRegisterBeacon ? "block" : "none";
    let isPrintCertificate = RegistrationState.isPrintCertificate;
    console.log("Home: RegisterBeacon -> render -> currentStep beacon", beacon, currentStep, isPrintCertificate);

    return (
      <>
        {/* <Container className='animated fadeIn faster cancel-btn-margin noprint' >
					{
						!RegistrationState.showCompleteScreen &&
						<a style={{display: display}} className='cancel-add-edit' onClick={this.onAskToCancle}>Cancel and return to dashboard</a>
					}
				</Container> */}

        {!RegistrationState.showCompleteScreen && display === "block" && (
          <Container className="animated fadeIn faster cancel-btn-margin noprint">
            <a style={{ display: display }} className="cancel-add-edit" onClick={this.onAskToCancle}>
              {i18n.t('cancel-and-return-to-dashboard')}
            </a>
          </Container>
        )}

        <Container className="registerBeacon-mainFormContainer animated fadeIn faster">
          <CurrentRegistrationStep
          isPrintCertificate = {isPrintCertificate}
            currentStep={currentStep}
            onSelectStep={this.onSelectStep}
            beacon={beacon}
            onClose={onClose}
            onDashboardEdit={onDashboardEdit}
            onSearchClick={onSearchClick}
            clearFiltersForUser={clearFiltersForUser}
            onSetFilterUsername={onSetFilterUsername}
            onUpdateSearch={onUpdateSearch}
            onAdminBeaconClick={onAdminBeaconClick}
            beaconEditError={beaconEditError}
          />
        </Container>

        <Modal centered={false} open={showModal} size={"tiny"} className="centered">
          <Modal.Content>
            <Header className="modal-header">WARNING</Header>
            Do you want to cancel editing and return to the dashboard?
            <div className="activityDetail-submitContainer">
              <Button className="registerBeacon-submitButton" onClick={this.onModalAccept}>
                {i18n.t('yes')}
              </Button>
              <Button className="registerBeacon-submitButton" onClick={this.onModalCancel} primary>
                {i18n.t('no')}
              </Button>
            </div>
          </Modal.Content>
        </Modal>
      </>
    );
  }
}

const CurrentRegistrationStep = ({ beaconEditError,currentStep, onSelectStep, beacon, onClose, onDashboardEdit, onSearchClick, clearFiltersForUser, onSetFilterUsername, onUpdateSearch, isPrintCertificate, onAdminBeaconClick }) => {
  console.log("Home: CurrentRegistrationStep -> currentStep", currentStep);
  switch (currentStep) {
    case 0:
      sendGtagEvent("regBcn_step1Finish")
      return (
        <>
          <MainSteps currentStep={currentStep} onSelectStep={onSelectStep} />
          <BeaconDetailForm decodedBeacon={beacon} beacon={beacon} onClose={onClose} />
        </>
      );
      break;
    case 1:
      sendGtagEvent("regBcn_step1Finish")
      return (
        <>
          <MainSteps currentStep={currentStep} onSelectStep={onSelectStep} />
          <OwnerDetailForm decodedBeacon={beacon} onClose={onClose} />
        </>
      );
      break;
    case 2:
      sendGtagEvent("regBcn_step2Finish")
      return (
        <>
          <MainSteps currentStep={currentStep} onSelectStep={onSelectStep} />
          <OwnerEmergencyContactForm decodedBeacon={beacon} onClose={onClose} />
        </>
      );
      break;
    case 3:
      sendGtagEvent("regBcn_step3Finish")
      return (
        <>
          <MainSteps currentStep={currentStep} onSelectStep={onSelectStep} />
          <VehiculeStep beacon={beacon} onClose={onClose} />
        </>
      );
      break;

    case 4:
      sendGtagEvent("regBcn_step4Finish")
      return (
        <>
          {(RegistrationState.showCompleteScreen || isPrintCertificate)  ? null : <MainSteps isPrintCertificate={isPrintCertificate} currentStep={currentStep} onSelectStep={onSelectStep} />}

          <BeaconDetailsReview
            onClose={onClose}
            onDashboardEdit={onDashboardEdit}
            onSearchClick={onSearchClick}
            clearFiltersForUser={clearFiltersForUser}
            onSetFilterUsername={onSetFilterUsername}
            beacon={beacon}
            onUpdateSearch={onUpdateSearch}
            onAdminBeaconClick={onAdminBeaconClick}
            beaconEditError={beaconEditError}
          />
        </>
      );

    case 4:
      return (
        <>
          <RegistrationComplete />
        </>
      );
      break;
  }
};

function sendGtagEvent(eventName){
  try {
    window.gtag("event", eventName, { event_category: eventName, event_label: eventName });
  } catch (error) {
    console.log("log ~ UserState ~ login ~ gtag error:", error);
  }
}

const MainSteps = ({ currentStep, onSelectStep, isPrintCertificate }) => {

  if(isPrintCertificate) return null;

  
  return (
  <Step.Group widths={5}>
    <Step active={currentStep >= 0} stepnumber={0}>
    
      <Step.Content>
        <Step.Title>{i18n.t('beacon-details')}</Step.Title>
      </Step.Content>
			  <StepComplete currentStep={currentStep} stepnumber={0} />
    </Step>

    <Step active={currentStep >= 1} stepnumber={1}>
     
      <Step.Content>
        <Step.Title>{i18n.t('owner-details')}</Step.Title>
      </Step.Content>
			 <StepComplete currentStep={currentStep} stepnumber={1} />
    </Step>

    <Step active={currentStep >= 2} stepnumber={2}>
     
      <Step.Content>
        <Step.Title>{i18n.t('emergency-contacts')}</Step.Title>
      </Step.Content>
			 <StepComplete currentStep={currentStep} stepnumber={2} />
    </Step>

    <Step active={currentStep >= 3} stepnumber={3}>
      
      <Step.Content>
        <Step.Title>{i18n.t('vehicle')}</Step.Title>
      </Step.Content>
			<StepComplete currentStep={currentStep} stepnumber={3} />
    </Step>

    <Step active={currentStep >= 4} stepnumber={4}>
     
      <Step.Content>
        <Step.Title>{i18n.t('summary')}</Step.Title>
      </Step.Content>
			 <StepComplete currentStep={currentStep} stepnumber={4} />
    </Step>
  </Step.Group>
)};

const StepComplete = ({ currentStep, stepnumber }) => {
  if (currentStep > stepnumber && RegistrationState.isNewRegisterBeacon) {
    return <Icon name="check" color="green" className="registerBeacon-stepComplete" />;
  } else {
    return null;
  }
};
